// api.js

import axios from 'axios'
import { Response, queryParam } from './_model'
import { FileModel } from './misc'

const BASE_URL = process.env.REACT_APP_BASE_URL + '/settings'

export const settings_api = async (data: queryParam): Promise<SettingModel> => {
	return await axios.get(`${BASE_URL}?${data.queryKey[1] || ''}`)
}
export const update_setting_api = async (data: SettingModel) => {
	return await axios.put(`${BASE_URL}`, data)
}
export const create_onboarding_settings_api = async (
	data: OnboardingSettingsModel
) => {
	return await axios.post(`${BASE_URL}/onboarding`, data)
}

export const update_onboarding_settings_api = async (
	data: OnboardingSettingsModel
) => {
	return await axios.put(`${BASE_URL}/onboarding/${data.id}`, data)
}
export const onboarding_settings_api = async (
	data: queryParam
): Promise<Response<OnboardingSettingsModel[]>> => {
	return await axios.get(`${BASE_URL}/onboarding?${data.queryKey[1] || ''}`)
}
export const delete_onboarding_settings_api = async (data: string) => {
	return await axios.delete(`${BASE_URL}/onboarding/${data}`)
}
export type SettingModel = {
	business_logo?: string
	business_name?: string
	business_email?: string
	business_phone?: string
	address?: string
	currency?: string
	default_rate_per_hour?: number
	invoice_default_due_days?: number
	payment_instructions?: string
	default_pay_period?: string
	primary_color?: string
	secondary_color?: string
	time_zone?: string
	use_clocking_time?: string
	invoice_template?: string
	use_live_location_for_clock_in?: string
	payroll_template?: string
	time_off_duration?: 'dates' | 'interval'
	tax_name?: string
	tax_rate?: number
	file?: FileModel
	media?: any
}
export type OnboardingSettingsModel = {
	id?: string
	name: string
	type: string
	setting_id?: string
	status?: boolean
}
